<template>
  <div>
    <div>
      <hb-header fewer-actions :padding="false" align-top class="mt-n2">
        <template v-slot:left>
          <hb-tabs
            v-model="activeTab"
            show-arrows
          >
            <v-tabs-slider color="#00848E"></v-tabs-slider>
            <v-tab
              v-for="menuOption in menuOptions"
              :key="menuOption.key"
              :href="'#tab-' + menuOption.key"
              @click="tabChanged(menuOption.key)"
            >
              {{ menuOption.label }}
            </v-tab>
          </hb-tabs>
        </template>
      </hb-header>
    </div>
    <div v-if="isPropertyLevel">
      <property-selector
        :property="property"
        @propertyChanged="propertyChanged"
      ></property-selector>
    </div>
  </div>
</template>

<script type="text/babel">
import PropertySelector from './PropertySelector';
import {mapGetters} from 'vuex';

export default {
  name: "MenuTabs",
  data() {
    return {
      activeTab: "corporate",
      property: null
    };
  },
  props: {
    menuOptions: {
      type: Array,
      default: () => [
        {
          key: "corporate",
          label: "Corporate Settings",
        },
        {
          key: "property",
          label: "Property Settings",
        }
      ]
    }
  },
  components: {
    PropertySelector
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
    }),
    isPropertyLevel() {
      return this.activeTab == 'tab-property' ? true : false;
    }
  },
  methods: {
    propertyChanged(property) {
      this.property = property;
      this.$emit('selectedProperty', property);
    },
    tabChanged(option) {
      if(!this.isPropertyLevel) this.property = null;
      this.$emit('setMenuOption', option);
    }
  }
}
</script>

<style lang="scss" scoped></style>